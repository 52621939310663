export default {
  views: {
    header: {
      menu_1: "CN / EN",
      subMenu_1a: "English",
      subMenu_1b: "简体中文",
    },

    home: {
      contactInfo: {
        title: "Contact",
        hkAddress:
          "Hong Kong Address: Room 1702, Infinitus Plaza, 199 Des Voeux Road Central, Sheung Wan, Hong Kong",
        contactPerson_1: "Henry, Yip Ngai Hang",
        contactEmail_1: "henryyip@jxsjchem.com",
        contactNo_1: "(+852) 28510255",
        cnAddress:
          "China (Mainland) Address: West of Pinghai Road, Jiaxing Port District, Zhejiang (314201)",
        contactPerson_2: "Chen Xian",
        contactEmail_2: "chenx@jxsjchem.com",
        contactNo_2: "(+86) 57385286836",
      },

      content: {
        p1: "Capitol International Limited and its subsidiaries was principally engaged in the manufacture and supply of ethylene oxide (“EO”), ethylene glycol (“EG”), propylene, polypropylene (“PP”), methyl tert-butyl ether (“MTBE”) and surfactants in the PRC. The Group was also engaged in the provision of processing services for PP, MTBE and surfactants to its customers and the production and supply of other chemical products such as carbon four(“C4”), crude pentene and industrial gases, namely oxygen, nitrogen and argon in the PRC. EO is a key intermediary component for the production of ethylene derivative products such as ethanolamines and glycol ethers and a wide range of surfactants. EG is a type of semi-finished goods that are used to produce other bio- organic chemical products such as mono ethylene glycol which is used to produce polyester and anti-frozen chemical liquids. Propylene is commonly used in the production of PP, acrylonitrile, propylene oxide, acetone, etc., to produce a variety of important organic chemical raw materials and to produce synthetic resin, synthetic rubber and some other fine chemicals. PP is a kind of thermoplastic resin, which can be used in knitting products, injection moulding products, film products, fibre products, pipes, etc. MTBE is a kind of gasoline additive, used as an oxygenate to raise the octane number and is almost exclusively used as a fuel component in fuel for gasoline engines. Surfactants are widely applied in different industries as scouring agents, moisturising agents, emulsifiers and solubilisers.",
        p2a: "In the opinion of the directors, the holding company of the Company is China Sanjiang Fine Chemicals Company Limited ",
        p2b: "(",
        p2c: ").",
      },
    },
  },
};
